import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const { Panel } = Collapse;
// import { CaretRightOutlined } from '@ant-design/icons';

const faqs = [
    {
        title: 'What is a Podcast (que es un podcast)?',
        sub: `Podcasts are usually original audio or video recordings. Sometimes they can also be recorded broadcasts of a tv or radio program. 
        The strict definition of a podcast is that it is an episodic series of spoken-word digital audio files.
        A podcast series usually features one or more recurring hosts engaged in a discussion about a particular topic or current event. s
        Podcasts have been around for quite a while now and have become increasingly popular in the recent years due to some of the shows like 
        <a target='_blank' rel="noopener noreferrer nofollow" href='https://serialpodcast.org/'>Serial by Sarah Koenig</a>, NPR podcasts and Slate podcasts.
        You can learn more about <a href='/what-is-podcast'>what is a podcast here.</a>
        `
    },
    // The definition of a podcast, podcast meaning, sarah koenig, podcast hosts, 
    //     how do podcasts work, podcast examples, podcast table or podcast desk, podcast equipment bundle, podcast kits, podcast background
    {
        title: 'Who invented podcasts?',
        sub: `Adam Curry and Dave Winer are credited with the invention of podcasting. The term podcasting was mentioned by Ben Hammersley in The Guardian newspaper article.
        <a target='_blank' rel="noopener noreferrer nofollow" href='https://podurama.com/podcast/this-week-in-tech-audio-i73329404'>This week in Tech</a> was the first podcast to 
        win People's choice awards at the Podcast Awards. CEO of Apple Steve Jobs once demonstrated how to make a podcast using Garageband in his keynote.`
    },
    // first podcast, cool podcasts, podcast icon, podcast table, podcast starter kit, podcast ideas, podcast editing software, podcast script, podcast intro,
    //     podcast interview questions, podcast intro music, podcast promotion, how to start a podcast
    {
        title: 'How to start a podcast?',
        sub: `There are many podcast starter kits on the internet. You can find podcast editing software and podcast icon as well by just a google search. If you already 
        have a podcast idea, you can start right away. If not listen to some of the existing cool podcasts and think of new podcast ideas. Depending on the type of podcast 
        you have in mind, you mighr beed a podcast script or some podcast interview questions. Once you record your first podcast, you can make it sound more professional by 
        adding podcast intro music as well. You can then focus on <a href='/how-to-promote-podcast'>podcast promotion and marketing</a> as it is important to stand out amoung millions of podcasts.`
    },
    // podcast to listen to, make a podcast, record a podcast, cite a podcast, making money podcasting, script for podcast, what makes a good podcast, podcasting tips,
    //     free podcast hosting, podcast name generator, podcast recording app;podcast creator app; anchor app podcast; podcast app icon
    // {
    //     title: 'What are some podcast topics?',
    //     sub: ''
    // },
    // interesting podcasts, kids podcast, podcast genres, podcast awards, podcast lists, podcast stories, podcast directory, funny or comedy podcasts, podcast ucsd
    {
        title: 'Are podcasts free?',
        sub: `Most podcasts are distributed for free through rss feeds. You can use any podcast app like 
        <a href='https://podurama.com/home'>Podurama</a> to listen to those podcasts.`
    },
    {
        title: 'What is podcast rss feed?',
        sub: 'An rss feed is like a podcast directory where podcast hosts upload their show information.'
    },
    // podcast app, podcast websites, podcast networks, podcast production, podcast bundle, podcast studio, podcast merch
    {
        title: 'Where do people listen to podcasts?',
        sub: `A large number of people listen to podcasts on their mobile devices during commute. Many people also listen to podcasts during work by playing them in the 
        background on desktop or webApps. Choose an app like <a href='https://podurama.com/home'>Podurama</a> where you can listen on both mobile and desktop devices for a better listening experience.
        You can learn more about <a href='/how-to-listen-to-podcasts'>how to listen to podcasts here and check some of the <a href='https://bestpodcast.app/'>best podcast apps here</a></a>`
    },
    {
        title: 'When to listen to podcasts?',
        sub: `A large number of people listen to podcasts on their mobile devices during commute. Many people also listen to podcasts during work by playing them in the 
        background on desktop or webApps. Choose an app like <a href='https://podurama.com/top-charts'>Podurama</a> where you can listen on both mobile and desktop devices for a better listening experience.`
    },
    {
        title: 'What are best podcasts of all time or any podcast recommendations?',
        sub: `There have been many hit podcast shows over the years. Sarah koenig made serial podcast which was one of the <a target='_blank' rel="noopener noreferrer" href='https://podurama.com/featured/best-true-crime-podcasts'>best true crime podcasts</a> all time. There are also 
        <a target='_blank' rel="noopener noreferrer nofollow" href='https://www.iheart.com/podcast/256-the-savage-nation-podcast-31142973/'>Michael Savage podcast</a>, 
        <a target='_blank' rel="noopener noreferrer nofollow" href='http://www.wtfpod.com/'>Marc Maron wtf podcast</a>, 
        <a target='_blank' rel="noopener noreferrer nofollow" href='https://counterclockpodcast.com/'>Counterclock podcast</a>, 
        <a target='_blank' rel="noopener noreferrer nofollow" href='https://www.audacy.com/podcasts/x22-report-21993'>X22 report podcast</a>, 
        <a target='_blank' rel="noopener noreferrer nofollow" href='https://podcasts.apple.com/us/podcast/morbid-a-true-crime-podcast/id1379959217'>morbid podcast hosts</a>, 
        <a target='_blank' rel="noopener noreferrer nofollow" href='https://twitter.com/RealCharlieWard?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor'>Charlie Ward podcast</a> to 
        name a few other popular shows. You can check our platform to search for podcasts and lookup podcast stats, podcast rankings, podcast reviews, and find 
        <a target='_blank' rel="noopener noreferrer" href='https://podurama.com/featured/best-history-podcasts'>best history podcasts</a>, <a target='_blank' rel="noopener noreferrer" href='https://podurama.com/featured/best-finance-podcasts'>best investing podcasts</a> and others.`
    },
    {
        title: 'How to listen to podcasts?',
        sub: `You can choose any of the podcast apps or rss readers to listen to podcasts. Although there are very few apps which are available for all platforms
         and which will let you sync your data across unlimited devices. <a href='https://podurama.com/top-charts'>Podurama</a> is one of them if you want to give it a try.`
    },
    {
        title: 'What are some podcast apps iphone?',
        sub: 'There are few podcast software for iphone like apple podcasts app, Spotify and Podurama. Podurama can be used for podcasts download and even for offline listening.'
    },
    {
        title: 'Is there android podcast app or how to listen to podcasts on android?',
        sub: 'Podurama podcast app is available on google play and is rated by many as the best podcast app for android. Our podcast app also has sleep timer so that you can take a nap by setting the podcast to stop in a few minutes.'
    },
    {
        title: 'What are some podcast apps web or desktop?',
        sub: 'There are only few podcast apps for desktop as most of them focus on mobile. Podurama is the major player for desktop with a podcast app for mac and podcast player for Windows 10.'
    },
    {
        title: 'Are there video podcasts?',
        sub: 'Most podcasts are audio but there are few podcasts which also have video. Podurama apps also support video podcasts.'
    },
    {
        title: 'What about books?',
        sub: `We also have a micro-learning app, called <a href='https://tedapp.ai'>Accelerated</a>, where you can learn all the key ideas from 9000+ non-fiction books in 15min. You can see how it compares to <a target='_blank' rel="noopener noreferrer" href='https://medium.com/illumination/accelerated-vs-blinkist-vs-headway-best-learning-app-2024-fb1d2eb1a4f3'>Headway vs Blinkist.</a>`
    },
];

export default class FAQ extends Component {
    render() {
        return (
            <div>
                <h2 className='serif-bold-extra'>FAQs for beginners</h2>
                <Collapse style={{ textAlign: 'left' }} defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                    <FontAwesomeIcon className='' icon={['fas', 'caret-right']} style={{}} />}>
                    {faqs.map((faq, index) => (
                        <FaqArticle faq={faq} key={`${index + 1}`} />
                        // <article {...props} key={`${index + 1}`}>
                        //     <Panel forceRender={true} header={<header>{faq.title}</header>} key={`${index + 1}`}>
                        //         <p>{faq.sub || ''}</p>
                        //     </Panel>
                        // </article>
                    ))}
                </Collapse>
                <style jsx>
                    {`
                        :global(.ant-collapse-header) {
                            display: flex;
                            flex-wrap: nowrap;
                            align-items: center; // flex-start;
                            padding: 12px 16px;
                            color: #000000d9;
                            line-height: 1.5715;
                            cursor: pointer;
                            transition: all .3s,visibility 0s;
                        }
                        :global(.ant-collapse-item) {
                            border-bottom: 1px solid #d9d9d9;
                        }
                    `}
                </style>
            </div>
        )
    }
}

function FaqArticle(props) {
    return (
        <article {...props}>
            <Panel {...props} forceRender={true} header={<header style={{ marginLeft: '8px' }}><h3 className='quicksand' style={{ marginBottom: 0 }}>{props.faq.title}</h3></header>} key={`${props.key}`}>
                <p className='serif' dangerouslySetInnerHTML={{ __html: props.faq.sub || '' }}></p>
            </Panel>
        </article>
    );
}

FaqArticle.propTypes = {
    faq: PropTypes.any,
    key: PropTypes.any,
}
